import { render, staticRenderFns } from "./SearchTest.vue?vue&type=template&id=48890c02&scoped=true"
import script from "./SearchTest.vue?vue&type=script&lang=js"
export * from "./SearchTest.vue?vue&type=script&lang=js"
import style0 from "./SearchTest.vue?vue&type=style&index=0&id=48890c02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48890c02",
  null
  
)

export default component.exports