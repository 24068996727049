<template>
    <el-drawer
      title="我嵌套了 Form !"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      direction="ltr"
      custom-class="demo-drawer"
      ref="drawer"
      :append-to-body="true"
      size="40%"
      :withHeader="false"
      :destroy-on-close="true"
      >
      <div class="content">
        <div class="video">
          <video id="video" controls="controls" autoplay :src="src"></video>
        </div>
        <el-form :model="search_op" label-position="top" style="width:90%;margin-top: 10px;">
          <el-form-item :label-width="formLabelWidth">
            <el-input v-model="search_op.search_name" autocomplete="off">
              <el-button slot="append" type="primary" @click="getList">搜索</el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <p>分词结果：{{jiebaList}} 总结果：{{total}}</p>
        <el-table
        :data="tableList"
        height="80"
        v-loading="table_loading"
        style="width: 100%">

        <el-table-column
          prop="name"
          label="音效名">
        </el-table-column>

        <el-table-column
          prop="download_times"
          width="100"
          label="下载次数">
        </el-table-column>

        <el-table-column
          prop="classify"
          width="100"
          label="分类">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-video-play"
              @click="play(scope.row)">播放</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
      </div>
    </el-drawer>
    </template>
    
    <script>
import apis from "@/api/index"
    export default {
      name: 'SearchTest',
      data() {
        return {
            dialogVisible: false,
            loading: false,
            formLabelWidth: '80px',
            tableList:[],
            src: "",
            jiebaList:[],
            total:0,

            page_num:1,
            page_size:5,
            search_op:{
              search_name:"",
            },
            table_loading:false,
        };
      },    
      methods: {
        show(search_name){
            this.src= ""
            this.search_op.search_name = search_name
            this.getList()
            this.dialogVisible = true;
        },
        handleClose(done) {
          if (this.uploadBtnLoading || this.isDownloadBtnLoading) {
            return;
          }
          done()
          // this.$confirm('确定要提交表单吗？')
          // .then(_ => {
          //     this.loading = true;
          //     this.timer = setTimeout(() => {
          //         done();
          //         // 动画关闭需要一定的时间
          //         setTimeout(() => {
          //         this.loading = false;
          //         }, 400);
          //     }, 2000);
          // })
          // .catch(_ => {});
        },
        play(row){

            let full_path = `http://wnyx.jinxuezhe.com/${row.path}`;
            if(typeof row.hostname != "undefined" && row.hostname != '' && row.hostname !=null){
                full_path = `${row.hostname}/${row.path}`;
            }
            if(typeof row.full_path != "undefined" && row.full_path != '' && row.full_path !=null){
                full_path = row.full_path;
            }

            this.src = full_path
        },
        getList(toS=true){
          if(toS){
            this.page_num = 1;
          }
          let op = {
            page_num:this.page_num,
            page_size:this.page_size
          }
          Object.assign(op,this.search_op)
          this.table_loading = true
          apis.searchSoundLog.searchTest(op).then(res => {
            console.log("search",res)
            if(res.data.code == 1){
              this.tableList = res.data.data.list;
              this.jiebaList = res.data.data.jieba
              this.total = res.data.msg
              this.$message ({
                type:'success',
                message: '获取成功'
              });
            }else{
              this.$message({
                type: 'error',
                message: '获取失败'+res.msg
              });
            }
            this.table_loading = false
          }).catch(err=>{
            this.$message({
              type: 'error',
              message: '获取失败'+err
            });
            this.table_loading = false
          })
        },
        handleSizeChange(val) {
          this.page_size = val
          this.getList(false)
          console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
          this.page_num = val
          this.getList(false)
          console.log(`当前页: ${val}`);
        },
      },
      mounted() {


      }
    
    }
    </script>
    
    <style lang="scss" scoped>
    .content{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        padding:0px 20px 0 20px; 
    
      .video{
        margin-top: 10px;
        box-sizing:border-box;
        width: 100%;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #000;
        video{
          width: 100%;
          height: 100%;
        }
      }
      .audio{
        padding:0px 20px 0 20px; 
        margin-top: 10px;
        box-sizing:border-box;
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // background-color: #000;
        audio{
          width: 100%;
          height: 100%;
        }
      }
    }
    .footer{
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 20px;
    }
    </style>